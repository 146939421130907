@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@font-face {
  font-family: "Arial";
  font-style: normal;
  font-weight: normal;
  src: local("Arial Regular"), url("./assets/fonts/arial/ARIAL.woff") format("woff");
}
body {
  padding-top: constant(safe-area-inset-top);
  /* iOS 11.0 */
  padding-top: env(safe-area-inset-top);
  /* iOS 11.2 */
}

* {
  transition: all 500ms;
  font-weight: 400;
}

[class^=flaticon-]:before,
[class*=" flaticon-"]:before,
[class^=flaticon-]:after,
[class*=" flaticon-"]:after {
  font-size: inherit;
  font-size: inherit;
}

p,
li {
  font-size: 14px;
}

p {
  margin: 0;
}

.p-0 {
  padding: 0;
}

.p-t-0 {
  padding-top: 0 !important;
}

.p-v-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.m-t-16 {
  margin-top: 16px !important;
}

.m-t-36 {
  margin-top: 36px !important;
}

.m-l-16 {
  margin-left: 16px !important;
}

.m-r-16 {
  margin-right: 16px !important;
}

.m-b-16 {
  margin-bottom: 16px !important;
}

.row-align-center {
  align-items: center;
}

.m-t-26 {
  margin-top: 26px;
}

.m-b-26 {
  margin-bottom: 26px;
}

.m-b-36 {
  margin-bottom: 36px !important;
}

.m-v-16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.m-0 {
  margin: 0 !important;
}

.txt-error {
  color: var(--ion-color-danger) !important;
  margin-top: 5px;
}

.block {
  display: block;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.section-title {
  text-align: center;
}
.section-title h3,
.section-title h1 {
  font-size: 26px;
  margin: 0;
  text-transform: capitalize;
}
.section-title p {
  font-size: 16px;
  color: var(--ion-color-primary);
}
.section-title .line {
  display: inline-block;
  height: 0;
  width: 124px;
  border-top: 3px solid var(--ion-color-primary);
}

.title-2 {
  font-size: 18px;
  margin-bottom: 0;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

[class^=flaticon-]:before,
[class*=" flaticon-"]:before,
[class^=flaticon-]:after,
[class*=" flaticon-"]:after {
  margin-left: 0;
}

.color-primary {
  color: var(--ion-color-primary);
}

.color-success {
  color: var(--ion-color-success);
}

.pointer {
  cursor: pointer;
}

ion-content {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
}

.main-container {
  min-height: calc(100% - 100px);
}

.spinner,
.no-data {
  /*position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);*/
  margin: 256px auto;
  text-align: center;
}
.spinner ion-img,
.no-data ion-img {
  width: 124px;
  margin: auto;
}

.white-container {
  background: #fff;
  padding: 24px;
  /* box-shadow: var(--box-shadow);*/
  border-radius: var(--ion-color-medium);
  overflow: hidden;
  border: var(--ion-color-medium) 1px solid;
  border-radius: var(--ion-border-radius);
}
.white-container .header {
  background: var(--ion-color-medium);
  margin: -24px -24px 0 -24px;
  overflow: hidden;
  display: flex;
  padding: 8px 24px;
  align-items: center;
  justify-content: space-between;
}
.white-container .header P {
  font-weight: 500;
}

ion-button {
  --box-shadow: 0;
  text-transform: capitalize;
  margin: 0;
}
ion-button.button-disabled {
  cursor: not-allowed;
}

.btn-group-outline {
  border: var(--ion-color-success) 1px solid;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
}
.btn-group-outline ion-button {
  width: 33.33%;
  --border-radius: 0;
  --background: transparent;
  --color: var(--ion-color-success);
  --border-width: 0 1px 0 0;
  --border-color: var(--ion-color-success);
}
.btn-group-outline ion-button.active {
  --background: var(--ion-color-success);
  --color: #fff;
}

.btn-1 {
  height: 50px;
  margin: 0;
}
.btn-1.i-start i {
  font-size: 21px;
  margin-right: 8px;
}

.btn-1.icon-start {
  position: relative;
  --padding-start: 50px;
  --padding-end: 32px;
}
.btn-1.icon-start i {
  font-size: 26px;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.btn-2 {
  height: 42px;
  margin: 0;
  --padding-start: 26px;
  --padding-end: 26px;
}
.btn-2 ion-icon {
  font-size: 21px;
}

.btn-2.i-start i {
  margin-right: 8px;
  font-size: 21px;
}

.btn-2.outline {
  --background: #fff;
}
.btn-2.outline i {
  margin-right: 16px;
}

.btn-sml {
  margin: 0;
  height: 32px;
  --padding-start: 16px;
  --padding-end: 16px;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
}

/***********************************************/
/***************forms**********************/
.select-item {
  border: #ccc 1px solid;
  border-radius: 12px;
}

.form-input {
  background: var(--ion-color-light);
  margin-top: 12px;
  border-radius: 4px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  --padding-start: 16px;
  --padding-end: 16px;
}

/******************************************/
.breadcrumbs-wrapper {
  margin: 26px 0;
}
.breadcrumbs-wrapper ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.breadcrumbs-wrapper ul li {
  display: inline-block;
  margin-right: 6px;
  color: #aaa;
  font-weight: 100;
}
.breadcrumbs-wrapper ul li i {
  font-size: 10px;
}
.breadcrumbs-wrapper ul li.link {
  cursor: pointer;
}
.breadcrumbs-wrapper ul li.active {
  color: var(--ion-color-secondary);
  font-weight: 500;
}

/****************************************************************************************/
.item-container {
  transition: all 500ms;
  position: relative;
  width: 1336px;
  max-width: 100%;
  margin: auto;
}
.item-container.chat-open {
  margin: 0;
}

.items-grid-container {
  position: relative;
  width: 100%;
}
.items-grid-container ion-grid {
  --ion-grid-padding: 0;
}
.items-grid-container ion-grid ion-col {
  --ion-grid-column-padding: 16px ;
}

.item-row {
  text-align: center;
  margin: 0 -10px;
}
.item-row .item-col {
  display: inline-block;
  margin-bottom: 25px;
  padding: 0 10px;
  position: relative;
  box-sizing: border-box;
}
.item-row .item-col.one-col {
  width: 100%;
}
.item-row .item-col.two-col {
  width: 50%;
}
.item-row .item-col.three-col {
  width: 33.33%;
}
.item-row .item-col.four-col {
  width: 25%;
}
.item-row .item-col.five-col {
  width: 20%;
}
@media (max-width: 576px) {
  .item-row {
    margin: 0 -6px;
  }
  .item-row .item-col {
    padding: 0 5px;
  }
  .item-row .item-col.sm-col-1 {
    width: 100%;
  }
  .item-row .item-col.sm-col-2 {
    width: 50%;
  }
  .item-row .item-col.sm-col-3 {
    width: 33.33%;
  }
  .item-row .item-col.sm-col-4 {
    width: 25%;
  }
  .item-row .item-col.sm-col-5 {
    width: 20%;
  }
  .item-row .item-col.sm-col-6 {
    width: 16.66%;
  }
  .item-row .item-col.sm-col-7 {
    width: 16.66%;
  }
  .item-row .item-col.sm-col-8 {
    width: 12.5%;
  }
  .item-row .item-col.sm-col-9 {
    width: 11.11%;
  }
  .item-row .item-col.sm-col-10 {
    width: 10%;
  }
  .item-row .item-col.sm-col-11 {
    width: 9.09%;
  }
  .item-row .item-col.sm-col-12 {
    width: 8.33%;
  }
}
@media (min-width: 576px) {
  .item-row .item-col.md-col-1 {
    width: 100%;
  }
  .item-row .item-col.md-col-2 {
    width: 50%;
  }
  .item-row .item-col.md-col-3 {
    width: 33.33%;
  }
  .item-row .item-col.md-col-4 {
    width: 25%;
  }
  .item-row .item-col.md-col-5 {
    width: 20%;
  }
  .item-row .item-col.md-col-6 {
    width: 16.66%;
  }
  .item-row .item-col.md-col-7 {
    width: 16.66%;
  }
  .item-row .item-col.md-col-8 {
    width: 12.5%;
  }
  .item-row .item-col.md-col-9 {
    width: 11.11%;
  }
  .item-row .item-col.md-col-10 {
    width: 10%;
  }
  .item-row .item-col.sm-col-11 {
    width: 9.09%;
  }
  .item-row .item-col.md-col-12 {
    width: 8.33%;
  }
}
@media (min-width: 1366px) {
  .item-row .item-col.lg-col-1 {
    width: 100%;
  }
  .item-row .item-col.lg-col-2 {
    width: 50%;
  }
  .item-row .item-col.lg-col-3 {
    width: 33.33%;
  }
  .item-row .item-col.lg-col-4 {
    width: 25%;
  }
  .item-row .item-col.lg-col-5 {
    width: 20%;
  }
  .item-row .item-col.lg-col-6 {
    width: 16.66%;
  }
  .item-row .item-col.lg-col-7 {
    width: 16.66%;
  }
  .item-row .item-col.lg-col-8 {
    width: 12.5%;
  }
  .item-row .item-col.lg-col-9 {
    width: 11.11%;
  }
  .item-row .item-col.lg-col-10 {
    width: 10%;
  }
  .item-row .item-col.lg-col-11 {
    width: 9.09%;
  }
  .item-row .item-col.lg-col-12 {
    width: 8.33%;
  }
}

app-item-thumb,
app-prduct-thumb,
app-product-thumb-one,
app-product-thumb-two,
app-product-thumb-three,
app-product-thumb-four,
app-product-thumb-five,
app-product-thumb-six,
app-product-thumb-seven,
app-product-thumb-eight,
app-product-thumb-nine,
app-product-thumb-ten {
  width: 100%;
}

.item-wrap {
  width: 100%;
  background: #fff;
  border-radius: var(--ion-border-radius);
  overflow: hidden;
  /*border: #fff 1px solid;*/
  border: var(--ion-color-light) 1px solid;
  cursor: pointer;
  transition: all 500ms;
  position: relative;
  box-shadow: var(--box-shadow);
}
.item-wrap .discount-label {
  position: absolute;
  background: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  top: 8px;
  left: 8px;
  display: inline-flex;
  border-radius: var(--ion-border-radius);
  line-height: 1;
  font-size: 12px;
  z-index: 1;
  padding: 6px 16px;
  font-weight: 400;
}
.item-wrap:hover {
  border-color: var(--ion-color-primary);
  box-shadow: var(--box-shadow);
}
.item-wrap .img-wrap {
  line-height: 0;
}
.item-wrap .img-wrap ion-img {
  width: 100%;
}
.item-wrap ion-img {
  width: 100%;
}
.item-wrap .item-detail-wrap {
  padding: 10px;
  text-align: left;
}
.item-wrap .item-detail-wrap .item-title,
.item-wrap .item-detail-wrap .item-price {
  font-size: 18px;
  text-transform: capitalize;
  display: block;
  font-weight: 400;
}
.item-wrap .item-detail-wrap .item-title del,
.item-wrap .item-detail-wrap .item-price del {
  color: #666;
  font-size: 16px;
  margin-left: 6px;
}
.item-wrap .item-detail-wrap .item-title,
.item-wrap .item-detail-wrap .item-description {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 14px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000;
  line-height: 1.2;
  min-height: 40px;
  text-decoration: none;
}
.item-wrap .item-detail-wrap .item-title a,
.item-wrap .item-detail-wrap .item-description a {
  color: #000;
  text-decoration: none;
}
.item-wrap .item-detail-wrap .item-description {
  line-height: 1;
  min-height: 20px;
  -webkit-line-clamp: 1;
}
.item-wrap .item-detail-wrap .product-variants-wrap .product-list-wrap {
  display: block;
  margin-bottom: 6px;
  padding: 8px;
  border: var(--ion-color-primary) 1px solid;
  color: var(--ion-color-primary);
  border-radius: var(--ion-border-radius);
  cursor: pointer;
  text-align: center;
}
.item-wrap .item-detail-wrap .product-variants-wrap .product-list-wrap i {
  margin-left: 6px;
  font-size: 11px;
  font-weight: 300;
}
.item-wrap .item-detail-wrap .item-price {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #000;
  font-size: 17px;
}
.item-wrap .item-detail-wrap .blank-space {
  min-height: 32px;
}
.item-wrap .btn-wrap {
  margin-top: 16px;
}

/***************forms**********************/
.input-wrap {
  margin-bottom: 12px;
}
.input-wrap ion-label {
  font-size: 16px;
  font-weight: 400;
  padding-left: 8px;
}
.input-wrap .error-msg {
  display: none;
}
.input-wrap.error .form-input {
  border: var(--ion-color-danger) 1px solid;
}
.input-wrap.error .error-msg {
  display: inline-block;
  color: var(--ion-color-danger);
  margin-top: 4px;
  font-weight: 400;
}

.form-input {
  background: #fff;
  margin-top: 6px;
  border-radius: 8px;
  --padding-top: 12px;
  --padding-bottom: 12px;
  --padding-start: 16px;
  --padding-end: 16px;
  border: #aaa 1px solid;
}

/******************************************/
.price-list-modal {
  --background: transparent;
  --box-shadow:none;
  --max-width:calc(100% - 32px);
}
.price-list-modal ion-content {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  --background: transparent;
}
.price-list-modal ion-content main {
  overflow: hidden;
}

ion-modal.promo-popup {
  --height: 100%;
  --width: 100%;
  --max-width: 100%;
  --background: transparent;
  --box-shadow: none;
}

.custom-modal ion-content {
  --padding-start: 16px;
  --padding-end: 16px;
  --padding-top: 16px;
  --padding-bottom: 16px;
  --background: #fff;
}
.custom-modal ion-content main {
  overflow: hidden;
}
.custom-modal ion-toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  height: auto;
  --padding-start: 0;
  --padding-end: 0;
  --background: #fff;
}
.custom-modal ion-toolbar ion-title {
  font-size: 18px;
  text-align: center;
  text-transform: capitalize;
  font-weight: 400;
}
.custom-modal ion-toolbar ion-footer {
  padding: 16px;
}

.image-modal {
  --width: 100%;
  --height: 100vh;
  --background: rgba(0,0,0,1);
}

.video-modal {
  --width: 50%;
  --background: transparent;
  --box-shadow: none;
}

.custom-modal.login-modal {
  --height: 400px;
  --max-width: 550px;
  --border-radius: 16px;
}

.custom-modal.login-side-modal {
  --height: 100%;
  --width: 500px;
  --border-radius: 0;
  width: 100%;
  justify-content: flex-end !important;
  left: auto;
  right: 0;
}

.request-btn {
  position: fixed;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}

/*********************************************/
.wishlist-label {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  background: #fff;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 36px;
  box-shadow: var(--box-shadow);
  z-index: 9;
}
.wishlist-label ion-icon {
  font-size: 26px;
}
.wishlist-label .heart-black {
  opacity: 0.5;
}
.wishlist-label .heart-red {
  color: #fd4d4d;
}

.d-flex-s-b {
  display: flex;
  align-items: center;
}

.flex-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.info-txt-w-icon {
  font-size: small;
  text-transform: none;
  margin: 10px 0;
}
.info-txt-w-icon span {
  margin-left: 3px;
}

.no-data-txt {
  font-size: 14px;
  opacity: 0.7;
  margin-top: 30px;
  text-align: center;
  text-transform: capitalize;
}

.line {
  border: 0;
  clear: both;
  display: block;
  width: 100%;
  background-color: #ccc;
  height: 1px;
  opacity: 0.4;
}

.modal-bg {
  background: rgba(0, 0, 0, 0.3) !important;
  --background: transparent !important;
}

.coupons-list-modal {
  --width: 32% !important;
  width: 100% !important;
}

.m-b-16 {
  margin-bottom: 16px;
}

.d-flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.opacity-06 {
  opacity: 0.6;
}

.m-t-8 {
  margin-top: 8px !important;
}

.opacity-07 {
  opacity: 0.7;
}

.f-w-b {
  font-weight: bold !important;
}

.refund-txt {
  font-size: small;
  margin: 10px 0;
}
.refund-txt ion-icon {
  margin-right: 5px;
  font-size: medium;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-wrap {
  background: white;
  padding: 15px;
  margin: 10px 0px;
  position: relative;
}
.content-wrap .heading {
  font-size: 14px;
  text-transform: uppercase;
  opacity: 0.7;
}

.content-dark-bg {
  --background: #F2F2F2;
}

.t-a-c {
  text-align: center;
}

.my-10 {
  margin: 10px 0;
}

.data-field {
  font-size: 15px;
  margin-bottom: 30px;
}
.data-field ion-input, .data-field ion-textarea {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 14px;
}

.cat-desc-wrapper {
  text-align: center;
  margin: 20px 0;
}

@media (max-width: 576px) {
  .image-modal {
    --background: #000;
  }
}
@media (min-width: 1366px) {
  .chat-modal {
    --height: 100%;
    --width: calc(100% - 52px);
    width: 500px;
    justify-content: flex-end !important;
    left: auto;
    right: 0;
  }
}
@media (min-width: 768px) {
  .custom-modal {
    --border-radius: 16px;
  }

  .big-modal {
    --width: 50%;
    --height: 90%;
  }
}
/*********************************************/
@media (max-width: 1366px) {
  .item-container {
    width: 100%;
    padding: 0 8px;
  }

  .chat-modal {
    --height: 100%;
    --width: 100%;
  }
}
@media (max-width: 992px) {
  .coupons-list-modal {
    --width: 45% !important;
  }
}
@media (max-width: 768px) {
  .coupons-list-modal {
    --width: 50% !important;
  }
}
@media (max-width: 576px) {
  .xs-full {
    width: 100% !important;
    padding: 0 !important;
  }

  .item-wrap .item-detail-wrap .item-price {
    font-size: 14px;
  }

  .coupons-list-modal {
    --width: 100% !important;
  }

  .custom-modal.login-modal {
    --max-width: 90%;
  }
}
@media (max-width: 420px) {
  .coupons-list-modal {
    --width: 100% !important;
  }
}
.ionic4-star-rating button {
  outline: none;
}

.rating-section h6 {
  font-size: 14px;
  font-weight: bold;
}
.rating-section .avg-rating {
  --padding-start: 0px;
}
.rating-section .avg-rating-stars {
  display: flex;
  align-items: center;
}
.rating-section .avg-rating-stars .ionic4-star-rating {
  margin-right: 5px;
}
.rating-section .avg-rating .total-ratings {
  opacity: 0.8;
}
.rating-section .avg-rating .total-ratings span {
  margin-left: 2px;
}
.rating-section .see-all {
  --min-height: 20px;
  font-size: 13px;
}

.user-rating h6, .rating-section .user-rating h6 {
  font-size: 13px;
}
.user-rating p, .rating-section .user-rating p {
  margin-block-start: 5px;
  margin-block-end: 5px;
}
.user-rating-review {
  text-align: left;
  line-height: 1.4;
  font-size: 13px;
}
.user-rating-date {
  opacity: 0.6;
  font-size: 11px;
}
.user-rating-photos {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.user-rating-photos ion-img {
  width: 60px;
  height: 60px;
  object-fit: fill;
  margin-right: 15px;
  border: 1px solid #ccc;
}

.info-txt {
  color: red;
  font-size: 14px;
}

.m-l-5-p {
  margin-left: 5%;
}

.btn-wrap .cart-btn {
  width: 100%;
  --border-radius: var(--ion-border-radius);
  border-radius: var(--ion-border-radius);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  --padding-start:0;
  --padding-end:0;
}
.btn-wrap .cart-btn span.text {
  width: calc(100% - 42px);
}
.btn-wrap .cart-btn span.icon {
  width: 42px;
  height: 42px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  border-radius: var(--ion-border-radius);
  font-size: 11px;
}
.btn-wrap .cart-btn-wrap {
  width: 100%;
  --border-radius: 6px;
  border-radius: 6px;
  position: relative;
  background: var(--ion-color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--ion-color-primary-contrast);
  font-size: 16px;
  font-weight: 400;
}
.btn-wrap .cart-btn-wrap.prd-in-cart {
  background: var(--ion-color-medium);
  color: var(--ion-color-outline);
}
.btn-wrap .cart-btn-wrap.prd-in-cart span.cart-qty {
  display: inline-block;
  transform: translatex(4px);
}
.btn-wrap .cart-btn-wrap .btn-2 {
  width: 42px;
  --padding-start: 0;
  --padding-end: 0;
}
.btn-wrap .cart-btn-wrap .btn-2 i {
  font-size: 14px;
}
.btn-wrap ion-icon {
  font-size: 26px;
  position: absolute;
}
.btn-wrap ion-icon.decrement {
  left: 12px;
}
.btn-wrap ion-icon.increment {
  right: 12px;
}

.order-sub-txt {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  opacity: 0.8;
  font-size: 13px;
}
.order-sub-txt-icon {
  margin-left: 5px;
}

.filter-modal, .coupons-list-modal {
  --height: 100%;
  /*--width: calc(100% - 52px);*/
  width: 500px;
  max-width: 100%;
  justify-content: flex-end !important;
  left: auto;
  right: 0;
  box-shadow: #000 5px 10px 10px;
}

/*.subscription-label{
    position: absolute;
}*/
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 99;
}
.upload-btn-wrapper .upload-btn {
  color: #fff;
  background-color: var(--ion-color-primary);
  padding: 8px 20px;
  border-radius: 42px;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 16px;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;
}
.upload-btn-wrapper .upload-btn:disabled,
.upload-btn-wrapper .upload-btn [disabled] {
  opacity: 0.5;
  cursor: pointer;
}

ion-searchbar {
  --box-shadow: none;
  padding: 0 !important;
  border: #aaa 1px solid;
}
ion-searchbar input.searchbar-input {
  box-shadow: none !important;
}

.tooltip {
  position: relative;
  cursor: pointer;
}
.tooltip:hover .tooltiptext {
  opacity: 1;
  display: inline-block;
}
.tooltip .tooltiptext {
  opacity: 0;
  display: none;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  z-index: 1;
  top: calc(100% + 8px);
  left: 50%;
  transform: translatex(-50%);
  min-width: 100%;
}
.tooltip .tooltiptext:after {
  content: " ";
  position: absolute;
  bottom: 100%;
  /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.vendor-product-inactive {
  opacity: 0.5;
}

a {
  color: inherit;
  text-decoration: none;
}

.status-progress-container {
  position: relative;
  overflow: hidden;
}
.status-progress-container .status-progress {
  position: relative;
  list-style: none;
}
.status-progress-container .status-progress-item {
  position: relative;
  min-height: 75px;
  counter-increment: list;
  padding-left: 0.5rem;
}
.status-progress-container .status-progress-item:before {
  content: "";
  position: absolute;
  left: -27px;
  top: 26px;
  height: 49px;
  width: 2px;
  border-left: 2px solid var(--ion-color-success);
}
.status-progress-container .status-progress-item:after {
  content: "✓";
  position: absolute;
  top: 0;
  left: -40px;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  background: var(--ion-color-success);
  color: #fff;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  text-align: center;
}
.status-progress-container .status-progress-item:last-child:before, .status-progress-container .status-progress-item.last-status:before {
  border-left: none !important;
}
.status-progress-container .status-progress-item.status-missing {
  opacity: 0.5;
}
.status-progress-container .status-progress-item.status-missing:before {
  border-left: 2px solid #ccc;
}
.status-progress-container .status-progress-item.status-missing:after {
  background: #ccc;
  opacity: 0.6;
  color: #fff;
}
.status-progress-container .status-progress-item.status-cancelled:before {
  border-left: 2px solid var(--ion-color-danger);
  height: 30px;
}
.status-progress-container .status-progress-item.status-cancelled:after {
  content: "×";
  background: var(--ion-color-danger);
  color: #fff;
  font-size: 20px;
}
.status-progress-container .status-progress-item.hide-status {
  display: none;
}
.status-progress-container .status-progress-title {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}
.status-progress-container .status-progress-info {
  font-size: 13px;
  margin-block-start: 5px;
  margin-block-end: 5px;
}

.color-success {
  color: var(--ion-color-success);
}

.color-danger {
  color: var(--ion-color-danger);
}

.sold-by-vendor {
  height: 21px;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
}
.sold-by-vendor .vendor-name {
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.logo-custom-size ion-img, .logo-custom-size img {
  width: var(--logo-width) !important;
  height: var(--logo-height) !important;
}

.f-s-14 {
  font-size: 14px;
}

.fab-btn-1 i {
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 0;
  transform: translate(-50%, -50%);
}

/*********************widget**************************/
.widget-container {
  padding: 35px 0;
}

.widget-margin {
  padding-top: 70px;
  padding-bottom: 70px;
}

.splash-modal {
  --width: 100%;
  --height: 100%;
}

.cart-loader {
  --background: transparent;
}
.cart-loader ion-backdrop {
  background-color: transparent;
  opacity: 0 !important;
}
.cart-loader .loading-wrapper {
  display: none;
}

/*############ style one slider################# */
.style-one-slider-container .style-one-slider-wrapper {
  position: relative;
}
.style-one-slider-container .style-one-slider-wrapper:hover ion-button.nav {
  opacity: 1;
}
.style-one-slider-container .style-one-slider-wrapper:hover ion-button.nav.prev {
  left: -24px;
}
.style-one-slider-container .style-one-slider-wrapper:hover ion-button.nav.next {
  right: -24px;
}
.style-one-slider-container .style-one-slider-wrapper ion-button.nav {
  width: 36px;
  height: 36px;
  --border-radius: 36px;
  position: absolute;
  top: 50%;
  z-index: 9;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 500ms;
}
.style-one-slider-container .style-one-slider-wrapper ion-button.nav i {
  font-size: 16px;
}
.style-one-slider-container .style-one-slider-wrapper ion-button.nav.prev {
  left: 16px;
}
.style-one-slider-container .style-one-slider-wrapper ion-button.nav.next {
  right: 16px;
}
@media (max-width: 786px) {
  .style-one-slider-container .style-one-slider-wrapper ion-button.nav.prev {
    left: 0;
  }
  .style-one-slider-container .style-one-slider-wrapper ion-button.nav.next {
    right: 0;
  }
}
.style-one-slider-container .style-one-slider-wrapper ion-slides ion-slide {
  margin-bottom: 42px;
}
.style-one-slider-container .style-one-slider-wrapper ion-slides ion-slide .item-wrap {
  padding: 0;
}
.style-one-slider-container .style-one-slider-wrapper ion-slides ion-slide .item-wrap .btn-wrap {
  margin: 0;
}
@media screen and (max-width: 1200px) {
  .style-one-slider-container .style-one-slider-wrapper ion-slides ion-slide {
    padding: 0 4px;
  }
}
@media screen and (max-width: 1200px) {
  .style-one-slider-container .style-one-slider-wrapper:hover ion-button.nav {
    opacity: 1;
  }
  .style-one-slider-container .style-one-slider-wrapper:hover ion-button.nav.prev {
    left: 16px;
  }
  .style-one-slider-container .style-one-slider-wrapper:hover ion-button.nav.next {
    right: 16px;
  }
}